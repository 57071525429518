.carouselImg {
  height: 490px;
}

.carouselContainer {
  width: 791px;
}

.btn_card:hover {
  background-color: #eeebeb;
  transform: scale(1.05);
  transition: 0.7s;
  border-radius: 5px;
}
