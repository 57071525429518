/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #17327a;
// $warning: #f9cc01;
// $info: #3FA592;

$navbar-height: 9vh;
$footer-height: 25vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #17327a;
    color: #fff;

    .nav-link {
        color: #fff;
    }

    .nav-link:hover {
        color: #000;
        text-decoration: underline;
        background-color: #f9cc01;
        border-radius: 2px;
    }
}

#wrapper {
    min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #f9cc01;
    color: #000;

    a {
        color: #000;
    }

    // a:hover {
    //     color: #000;
    //     background-color: #2a53c4;
    // }
}

.brand-logo {
    height: 100px;
    width: auto;
}

.imgFounder{
    height: 450px;
    width: auto;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
    background-color: #17327a !important;
  }
  .swiper-button-prev {
    color: #17327a !important;
  }
  .swiper-button-next {
    color: #17327a !important;
  }
  .swiper-wrapper{
      height: 94% !important; 
  }

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"