.options_parent {
  position: relative;
}

.options_box {
  position: absolute;
  z-index: 10;
  margin-top: 10px;
}

input[type="radio"] {
  cursor: pointer;
}

input[type="radio"] + label {
  cursor: pointer;
}
